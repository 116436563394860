




































































import { Component, Vue, Ref, Prop, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalConfirmStudentCharge, { StudentData } from '@/components/organisms/ModalConfirmStudentCharge.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import LoadStudentsApi from '@/mixins/teacher/LoadStudentsApi'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    InputWithLabel,
    SelectBase,
    TableBase,
    ModalConfirmStudentCharge,
  },
})
export default class StudentCharge extends Mixins(QueryMethods, LoadClassOptionsApi, LoadStudentsApi) {
  @Prop()
  teacherId!: number
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private searchCount = 0
  private isProcessing = false
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '講師・メンター', href: '/teacher/setting/teacher' },
    { text: '', active: true },
  ]

  private nickname = ''

  private targetSelectedData = 0
  private targetOptionDatas = [
    { text: '全生徒', value: 0 },
    { text: '対象生徒のみ', value: 1 },
    { text: '対象外生徒のみ', value: 2 },
  ]

  private schoolOptionDatas: { text: string; value: number }[] = []
  private schoolSelectedData: number | null = null

  private gradeOptionDatas: { text: string; value: number }[] = []
  private gradeSelectedData: number | null = null

  private classOptionDatas: { text: string; value: string }[] = []
  private classSelectedData: string | null = null

  private attributeOptionDatas: { text: string; value: number }[] = []
  private attributeSelectedData: number | null = null

  private tableBaseCheckboxs = ['id']
  private tableBaseToglles = ['targetStudent']
  private tableMultipleLines = ['nameAndCode', 'attribute']
  private tableBaseFields = [
    { key: 'id', label: '' },
    { key: 'nameAndCode', label: '氏名・ID' },
    { key: 'school', label: '学校' },
    { key: 'grade', label: '学年' },
    { key: 'attribute', label: 'グループ' },
    { key: 'targetStudent', label: '授業対象' },
  ]

  private tableBaseItems: {
    id: { value: number; checked: boolean }
    nameAndCode: string[] // userNickname, studentCode
    school: string
    grade: string
    attribute: string[]
    targetStudent: {
      id: string
      checked: boolean
      onChange: { func: Function; funcParams: { studentId: number } }
    }
  }[] = []

  private get checkedStudentList(): StudentData[] {
    const students: StudentData[] = []
    this.tableBaseItems.forEach((item) => {
      if (item.id.checked) {
        students.push({
          id: item.id.value,
          // nameAndCodeは [name, code] の配列
          name: item.nameAndCode[0],
          code: item.nameAndCode[1],
          school: item.school,
          grade: item.grade,
        })
      }
    })
    return students
  }

  private isOperationAdd = true
  @Ref() confirmModal!: ModalConfirmStudentCharge

  private confirmAdd() {
    this.isOperationAdd = true
    this.confirmModal.show()
  }
  private confirmRemove() {
    this.isOperationAdd = false
    this.confirmModal.show()
  }

  private mounted() {
    this.loadInitialize()
  }

  // クエリから検索条件を復元
  private setParamsFromQuery() {
    const params = this.getParamsObject()
    // 氏名
    if (params['name']) {
      this.nickname = params['name']
    }
    // 対象生徒
    if (params['targetType']) {
      this.targetSelectedData = params['targetType']
    }
    // 学校
    if (params['school']) {
      this.schoolSelectedData = params['school']
    }
    // 学年
    if (params['grade']) {
      this.gradeSelectedData = params['grade']
    }
    // クラス
    if (params['class']) {
      this.classSelectedData = params['class']
    }
    // 属性
    if (params['attribute']) {
      this.attributeSelectedData = params['attribute']
    }
  }

  // クエリに検索条件を設定
  private setQueryFromParams() {
    const params = {}
    // 氏名
    if (this.nickname) {
      params['name'] = this.nickname
    }
    // 対象生徒
    params['targetType'] = this.targetSelectedData
    // 学校
    if (this.schoolSelectedData) {
      params['school'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['grade'] = this.gradeSelectedData
    }
    // クラス
    if (this.classSelectedData) {
      params['class'] = this.classSelectedData
    }
    // 属性
    if (this.attributeSelectedData) {
      params['attribute'] = this.attributeSelectedData
    }
    this.setUrlAsParams(params)
  }

  // 初回情報読み込み
  private async loadInitialize() {
    this.setParamsFromQuery()
    await this.loadTeacherName()
    await this.loadSelectDatas()
    this.loadStudentDatas()
  }

  // 教師名取得
  private async loadTeacherName() {
    Vue.prototype.$http.httpWithToken.get(`/teachers/${this.teacherId}`).then((res: any) => {
      this.breadcrumbs[this.breadcrumbs.length - 1].text = res.data.nickname
    })
  }

  // プルダウン用情報読み込み
  private async loadSelectDatas() {
    this.loadGrades()
    this.loadAttributes()
    this.loadSchools()
    this.loadClasses()
  }

  // 学年情報読み込み
  private async loadGrades() {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  // 属性情報読み込み
  private async loadAttributes() {
    // 属性プルダウンの情報設定
    Vue.prototype.$http.httpWithToken
      .get(`/user_attributes`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        this.attributeOptionDatas = res.data.userAttributes.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
          }
        })
      })
  }

  // 学校情報読み込み
  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }

  // 授業情報取得
  private async loadClasses() {
    this.classOptionDatas = await this.loadClassOptions(this.branchId)
  }

  // 検索
  private search() {
    this.setQueryFromParams()
    this.loadStudentDatas()
  }

  private getSearchParams(): any {
    const params = this.getSearchParamsBase(this.branchId)
    params['teacherId'] = this.teacherId

    // 氏名
    if (this.nickname) {
      params['name'] = this.nickname
    }
    // 対象生徒
    params['targetType'] = this.targetSelectedData
    // 学校
    if (this.schoolSelectedData) {
      params['schoolId'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['gradeId'] = this.gradeSelectedData
    }
    // クラス
    if (this.classSelectedData) {
      const classData = this.classSelectedData.split(',')
      params['isGroup'] = classData[0]
      params['classSettingId'] = classData[1]
      if (classData.length > 3) {
        params['period'] = classData[2]
      }
    }
    // 属性
    if (this.attributeSelectedData) {
      params['userAttributeId'] = this.attributeSelectedData
    }
    return params
  }

  // 生徒情報読み込み・検索
  private async loadStudentDatas() {
    // 固定パラメータ設定
    const params = this.getSearchParams()
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudents(params)
    this.searchCount = data.count
    this.tableBaseItems = data.students.map((student) => {
      return {
        id: { value: student.studentId, checked: false },
        nameAndCode: [student.nickname, student.studentCode],
        school: student.schoolName,
        grade: student.gradeName,
        attribute: [student.userAttributeTitle ? student.userAttributeTitle : '－'].concat(
          student.linkedAttributeTitles || []
        ),
        targetStudent: {
          id: `isTarget_${student.studentId}`,
          checked: student.isChargeStudent || false,
          onChange: {
            func: this.onChangeTargetStudentToggle,
            funcParams: { studentId: student.studentId },
          },
        },
      }
    })
  }

  // 授業対象生徒追加/削除
  private onChangeTargetStudentToggle(params: { studentId: number }, checked: boolean) {
    if (this.isProcessing) return
    this.isProcessing = true

    const apiPath = `/teachers/${this.teacherId}/charge_students/${checked ? 'add' : 'remove'}`
    Vue.prototype.$http.httpWithToken
      .post(apiPath, { studentIds: [params.studentId] })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
        this.search()
      })
      .finally(() => {
        // 授業対象の更新に成功した場合は再取得不要
        this.isProcessing = false
      })
  }
}
