




























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type StudentData = {
  id: number
  code: string
  name: string
  school: string
  grade: string
}

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalConfirmStudentCharge extends Mixins(ModalBaseMethod) {
  // 教師ID
  @Prop()
  teacherId!: number

  // 操作対象の生徒リスト
  @Prop()
  checkedStudentList!: StudentData[]

  // 追加/削除フラグ
  @Prop()
  isOperationAdd!: boolean

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted = this.checkedStudentList.length != 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  // 操作対象の生徒IDリスト
  private get studentIds(): number[] {
    return this.checkedStudentList.map((student) => {
      return student.id
    })
  }

  // 授業対象生徒の追加/削除
  private async saveChargeStudents() {
    const apiPath = `/teachers/${this.teacherId}/charge_students/${this.isOperationAdd ? 'add' : 'remove'}`
    Vue.prototype.$http.httpWithToken
      .post(apiPath, { studentIds: this.studentIds })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.hide()
        this.$emit('hide-modal')
      })
  }
}
