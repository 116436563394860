import { render, staticRenderFns } from "./ModalConfirmStudentCharge.vue?vue&type=template&id=405d3db8&scoped=true&lang=pug&"
import script from "./ModalConfirmStudentCharge.vue?vue&type=script&lang=ts&"
export * from "./ModalConfirmStudentCharge.vue?vue&type=script&lang=ts&"
import style0 from "./ModalConfirmStudentCharge.vue?vue&type=style&index=0&id=405d3db8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405d3db8",
  null
  
)

export default component.exports